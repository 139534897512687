<template>
  <div class="flex justify-center">
    <el-button size="small" type="primary" @click="selectImg">上传头像</el-button>
    <input
      ref="uploadImg"
      type="file"
      id="uploads"
      hidden
      accept="image/png, image/jpeg, image/jpg"
      @change="uploadImg($event, 1)"
    />
    <el-dialog
      title="上传头像"
      width="700px"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="dialogVisible = false"
    >
      <div class="img-upload flex justify-around">
        <div style="width: 500px; height: 500px">
          <VueCropper
            ref="cropper"
            :img="cropperOption.img"
            :outputSize="cropperOption.size"
            :outputType="cropperOption.outputType"
            :fixed="cropperOption.fixed"
            :fixedNumber="cropperOption.fixedNumber"
            :full="cropperOption.full"
            :autoCrop="cropperOption.autoCrop"
            :autoCropWidth="cropperOption.autoCropWidth"
            :autoCropHeight="cropperOption.autoCropHeight"
            :centerBox="cropperOption.centerBox"
            :infoTrue="cropperOption.infoTrue"
            @realTime="realTime"
          ></VueCropper>
        </div>
        <div class="flex flex-col mg-l-20">
          <div class="thumbnail">
            <div :style="previewStyle">
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </div>
          <div class="flex justify-center mg-t-20">
            <el-button @click="selectImg">选择图片</el-button>
          </div>
          <div class="flex justify-center mg-t-20">
            <el-button type="primary" @click="uploadAvatar">上传头像</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Button } from 'element-ui';
import { VueCropper } from 'vue-cropper';

import { saveUserAvatar } from '@/api/user';

export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    VueCropper,
  },
  props: {},
  data() {
    return {
      dialogVisible: false,

      fileName: undefined,
      fileType: undefined,

      cropperOption: {
        img: '', // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 输出截图是否缩放
        autoCrop: true, // 是否默认生成截图框
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 120, // 默认生成截图框宽度
        autoCropHeight: 120, // 默认生成截图框高度
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // 截图框展示宽高类型
      },

      previews: {},
      previewStyle: {},
    };
  },
  methods: {
    // 实时预览函数
    realTime(data) {
      let previews = data;

      this.previewStyle = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        margin: '0',
        zoom: 120 / previews.w,
      };
      this.previews = previews;
    },
    // 选择照片
    selectImg() {
      this.$refs.uploadImg.click();
    },
    // 上传图片
    uploadImg(e) {
      // this.cropperOption.img
      let file = e.target.files[0];

      // console.log(file);

      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        alert('图片类型必须是,jpeg,jpg,png中的一种');
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }

        this.cropperOption.img = data;
        this.fileName = file.name;
        this.fileType = file.type;
        this.$refs.uploadImg.value = '';

        if (this.dialogVisible === false) {
          this.dialogVisible = true;
        }
      };
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },
    // 上传头像
    uploadAvatar() {
      this.$refs.cropper.getCropBlob((theBlob) => {
        const file = new File([theBlob], this.fileName, { type: this.fileType, lastModified: Date.now() });
        const formData = new window.FormData();
        formData.append('file', file);
        formData.append('token', this.$store.getters.token);

        saveUserAvatar(formData)
          .then(() => {
            this.$message.success('头像上传成功');
          })
          .finally(() => {
            this.dialogVisible = false;
            this.$emit('finally');
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-upload {
  .thumbnail {
    border-radius: 50%;
    overflow: hidden;
    min-height: 120px;
    min-width: 120px;
    border: 1px solid #ccc;

    img {
      max-width: initial;
    }
  }
}
</style>
