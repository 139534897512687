<template>
  <div class="user-info user-wrapper">
    <div class="user-header">用户信息</div>
    <div class="user-container" v-loading="isLoading">
      <div class="flex flex-col" :style="{ height: '160px', marginRight: '88px' }">
        <!-- 上传头像 -->
        <img class="avatar" :src="avatarUrl" @error="avatarUrl = defalutAvatarUrl" />
        <ImgUpload @finally="updateAvatar" />
      </div>
      <div class="flex flex-col justify-around info">
        <div class="flex">
          <div class="label">帐号：</div>
          <div class="content">{{ user.shortUserId }}</div>
        </div>
        <div class="flex">
          <div class="label">姓名：</div>
          <div class="content">{{ user.userName }}</div>
        </div>
        <div class="flex">
          <div class="label">号码：</div>
          <div class="content">{{ user.mobileNo }}</div>
        </div>
        <div class="flex">
          <div class="label">签名：</div>
          <div class="content">{{ user.motto }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @desc 用户信息
 */
import { Button } from 'element-ui';
import ImgUpload from '../../components/imgUpload';
import { getUserInfo } from '@/api/common';

export default {
  components: {
    [Button.name]: Button,
    ImgUpload,
  },
  data() {
    return {
      isLoading: true,

      user: {
        photo: '',
        userId: '',
        userName: '',
        mobileNo: '',
        motto: '',
      },
      avatarUrl: '',
      defalutAvatarUrl: require('@images/components/header/default_avatar.svg'), // 默认头像
    };
  },
  methods: {
    getData() {
      this.isLoading = true;

      getUserInfo()
        .then(({ result }) => {
          this.user = result.user;
          if (result.user.photo) {
            this.avatarUrl = result.user.photo;
          } else {
            this.avatarUrl = this.defalutAvatarUrl;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateAvatar() {
      this.$store.dispatch('user/getUserInfo');
      this.getData();
    },
  },
  mounted() {
    this.avatarUrl = this.defalutAvatarUrl;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
@import '../../user.scss';

.user-info {
  .label {
    color: #8b8f8f;
  }
  .content {
    color: #2c2f2e;
  }
  .avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    overflow: hidden;
    border: 1px solid #8b8f8f;
  }
  .info {
    padding-left: 87px;
    border-left: 1px solid #dedede;
  }
}
</style>
